import React from "react";
import cx from "classnames";
import { motion } from "framer-motion";

import Container from "../Container/Container";

import "./Section.scss";

export const SectionColor = {
    defaultColor: 'section-color--default',
    blackColor: 'section-color--black'
};

const variants = {
    initial: { opacity: 0 },
    open: { opacity: 1 },
    exit: { opacity: 0 },
};

const Section = ({
    children,
    color = SectionColor.defaultColor,
    wrapperClassName,
    className,
    ...props
}) =>  (
    <motion.section
        variants={variants}
        {...props}
        className={cx("section", wrapperClassName, color)}>
        <Container className={cx("section__inner", className)}>
            {children}
        </Container>
    </motion.section>
);

export default Section;
